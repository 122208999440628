import React from "react"
import PropTypes from "prop-types"

import TeamMemberThumbCard from "../cards/TeamMemberThumbCard"
import BaseCarousel from "./BaseCarousel"

const TeamMembersCompactCarousel = ({ teamMembers }) => {
  return (
    <BaseCarousel
      elements={teamMembers}
      slidesToShowXs={2}
      render={teamMember => <TeamMemberThumbCard teamMember={teamMember} />}
    />
  )
}

export default TeamMembersCompactCarousel

TeamMembersCompactCarousel.propTypes = {
  teamMembers: PropTypes.array.isRequired,
}
