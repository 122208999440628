import React from "react"
import PropTypes from "prop-types"

import HelperCarouselThumbCard from "../cards/HelperCarouselThumbCard"
import BaseCarousel from "./BaseCarousel"

const HelpersCompactCarousel = ({ helpers, onSelect }) => {


  return (
    <BaseCarousel
      elements={helpers}
      slidesToShowXs={2}
      render={helper => <HelperCarouselThumbCard helper={helper} onSelect={() => onSelect(helper.uid)} />}
    />
  )
}

export default HelpersCompactCarousel

HelpersCompactCarousel.propTypes = {
  helpers: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
}
