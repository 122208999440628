import React from "react"
import PropTypes from "prop-types"

import VideoThumbCard from "../cards/VideoThumbCard"
import BaseCarousel from "./BaseCarousel"

const VideosCompactCarousel = ({ videos }) => {
  return (
    <BaseCarousel
      elements={videos}
      slidesToShowXs={1}
      render={video => <VideoThumbCard video={video} />}
    />
  )
}

export default VideosCompactCarousel

VideosCompactCarousel.propTypes = {
  videos: PropTypes.array.isRequired,
}
