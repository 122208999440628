import React from "react"
import PropTypes from "prop-types"

import ProcedureThumbCard from "../cards/ProcedureThumbCard"
import BaseCarousel from "./BaseCarousel"

const ProceduresCompactCarousel = ({ procedures }) => (
  <BaseCarousel
    elements={procedures}
    slidesToShowXs={2}
    render={procedure => <ProcedureThumbCard procedure={procedure} />}
  />
)

export default ProceduresCompactCarousel

ProceduresCompactCarousel.propTypes = {
  procedures: PropTypes.array.isRequired,
}
