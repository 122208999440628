import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Chip from '@material-ui/core/Chip';
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Collapse from '@material-ui/core/Collapse';

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import SEO from "../components/SEO"

import Breadcrumbs from "../components/Breadcrumbs"

import CustomButton from "../components/buttons/CustomButton"
import ContactPopup from "../components/contacts/ContactPopup"

import addToSchema from "../services/addToSchema"
import getTeamMemberLocations from "../services/getTeamMemberLocations"
import getSimilarTeamMembers from "../services/getSimilarTeamMembers"
import getSimilarHelpers from "../services/getSimilarHelpers"

import SectionTitle from "../components/SectionTitle"

import ArrowDownIcon from "../components/icons/ArrowDownIcon"
import ArrowUpIcon from "../components/icons/ArrowUpIcon"
import ProceduresCompactCarousel from "../components/carousels/ProceduresCompactCarousel"
import NewsCompactCarousel from "../components/carousels/NewsCompactCarousel"
import VideosCompactCarousel from "../components/carousels/VideosCompactCarousel"
import TeamMembersCompactCarousel from "../components/carousels/TeamMembersCompactCarousel"
import HelpersCompactCarousel from "../components/carousels/HelpersCompactCarousel"
import GeneralContact from "../components/contacts/GeneralContact"
import PhotoGallery from "../components/carousels/PhotoGallery"

const useStyles = makeStyles(theme => ({
  avatar: {
    width: "100%",
    height: 170,
    borderRadius: 0,

    [theme.breakpoints.up("md")]: {
      height: 250
    },
  },
  title: {
    display: "block",
    fontWeight: 700,
    fontSize: 28,
  },
  specialisation: {
    fontSize: 18,
    lineHeight: '23px'
  },
  chip: {
    marginRight: 5,
    marginBottom: 5,
    fontWeight: 700
  },
  description: {
    fontSize: 16,
    textAlign: 'justify'
  },
  cvToggle: {
    background: '#999',
    fontWeight: 700,

    '&:focus': {
      background: '#999',
    }
  },
  cvContent: {
    padding: theme.spacing(0, 2),
  },

  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
}))

const getSchema = (member, locations, procedures, videos) => {
  let schema = member.data.schema_json.text || ""

  schema = addToSchema(schema, {
    "@context": "https://schema.org",
    "@type": "Physician",
    name: member.data.name.text,
    medicalSpecialty:
      member.data.specialisations.filter(({ specialisation }) => specialisation.document).length > 0
        ? member.data.specialisations[0].specialisation.document.data.title.text
        : "",
    image: member.data.profile_image.url,
    knowsabout: member.data.competence,
    availableService: procedures.map(procedure => procedure.data.title.text),
    telephone: "0319300",
    priceRange: "$$",
  })

  locations.forEach(location => {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "hospitalAffiliation",
      name: location.data.long_title.text,
      Address: location.data.address.text.replace(/\n/g, ''),
      "addressLocality": location.data.city.text,
      image: location.data.gallery[0].image.url,
      "priceRange": "$$",
      "telephone": "0319300"
    })
  })

  videos.forEach(video => {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "description": video.title,
      "embedUrl": video.embedUrl,
      "name": video.title,
      "thumbnailUrl": [
        video.thumbnailUrl
      ],
      "uploadDate": new Date(member.first_publication_date).toISOString().split('T')[0],
      "genre": "Science & Technology",
      "author": "Centrele de Excelenta MONZA ARES"
    })
  })

  return schema
}

const TeamMemberPage = props => {
  const {
    data: { proceduresPage, member, news, newsPage, teamPage },
    pageContext: { locale, breadcrumbs },
    location
  } = props

  const classes = useStyles()
  const [showCV, setShowCV] = useState(false)
  const [selectedHelperUid, setSelectedHelperUid] = useState("-")
  const [contactModalOpen, setContactModalOpen] = useState(false)

  const locations = getTeamMemberLocations(member.uid) ?? []

  const similarTeamMembers = getSimilarTeamMembers(locations.map(l => l.uid), member.data.specialisations
    .filter(({ specialisation: s }) => s.document)
    .map(({ specialisation: s }) => s.document.uid))
    .filter(teamMember => teamMember.uid !== member.uid)
    .slice(0, 10)
    .map(teamMember => ({
      slug: `/${teamPage.uid}/${teamMember.uid}/`,
      data: teamMember.data
    }))

  let similarHelpers = getSimilarHelpers(locations.map(l => l.uid), member.data.specialisations
    .filter(({ specialisation: s }) => s.document)
    .map(({ specialisation: s }) => s.document.uid))
    .slice(0, 10)
    .map(helper => ({
      uid: helper.uid,
      data: helper.data
    }))

  if (!similarHelpers.length) {
    similarHelpers = getSimilarHelpers()
      .map(helper => ({
        uid: helper.uid,
        data: helper.data
      }))
  }

  const procedures = member.data.procedures
    .filter(({ procedure: p, title, image }) => p.document || (title && image))
    .map(({ procedure: p, title, image }) => ({
      slug: p.document ? `/${proceduresPage.uid}/${p.document.data.categories[0].category.uid}/${p.document.uid}/` : null,
      data: p.document?.data || { title, image, categories: [] },
    }))

  const videos = member.data.videos
    .filter(({ embed: video }) => video)
    .map(({ embed: video }) => ({
      embedUrl: video.embed_url,
      thumbnailUrl: video.thumbnail_url,
      title: video.title,
      id: video.id
    }))

  const photos = member.data.photo_gallery
    .filter(({ photo }) => photo)
    .map(({ photo }) => photo)

  const newsItems = news.edges.map(r => r.node)
    .map(a => ({
      slug: `/${newsPage.uid}/${a.uid}/`,
      data: a.data
    }))

  const onSelectHelper = helperUid => {
    setSelectedHelperUid(helperUid)
    setContactModalOpen(true)
  }

  return (
    <>
      <SEO
        schema={getSchema(member, locations, procedures, videos)}
        ogp={member.data.ogp_tags || ""}
        meta={{
          title: member.data.meta_title,
          description: member.data.meta_description,
          keywords: member.data.meta_keywords,
          author: "Medicina Interventionala",
        }}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <Container maxWidth="lg">
        <main>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <GatsbyImage
                        loading="eager"
                        className={classes.avatar}
                        alt={`${member.data.name.text}`}
                        image={member.data.profile_image.gatsbyImageData}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography component="h1" className={classes.title}>
                            {member.data.name.text}
                          </Typography>

                          {member.data.entitling && <Typography className={classes.specialisation}>
                            {member.data.entitling}
                          </Typography>}

                          {member.data.specialisations.filter(({ specialisation }) => specialisation.document).length > 0 && member.data.specialisations.map(({ specialisation }) =>
                            <Typography className={classes.specialisation} key={specialisation.document.id}>
                              {specialisation.document.data.title.text}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {locations.map(location =>
                            <Chip
                              component={Link}
                              to={`${localizedSlugPrefix(location)}/locatii/${location.uid}/`}
                              clickable
                              label={location.data.long_title.text}
                              key={location.id}
                              className={classes.chip}
                              color="primary" />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.description}>
                            {member.data.description.text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                {member.data.cv.text.length > 0 && (
                  <Grid item xs={12}>
                    <CustomButton
                      fullWidth
                      variant="contained"
                      className={classes.cvToggle}
                      onClick={() => setShowCV(!showCV)}
                    >
                      Citește CV complet
                      {showCV ? (
                        <ArrowUpIcon
                          style={{ fontSize: 14, height: 25, marginLeft: 5 }}
                        />
                      ) : (
                        <ArrowDownIcon
                          style={{ fontSize: 14, height: 25, marginLeft: 5 }}
                        />
                      )}
                    </CustomButton>


                    <Collapse in={showCV}><Grid container>
                      <Grid item>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: member.data.cv.html,
                          }}
                        />
                      </Grid>
                    </Grid></Collapse>

                  </Grid>
                )}

                {procedures.length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <SectionTitle component="h2">
                        Proceduri efectuate de {member.data.name.text}
                      </SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <ProceduresCompactCarousel procedures={procedures} />
                    </Grid>
                  </>
                }

                {member.data.investigations.text.length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <SectionTitle component="h2">
                        Investigații efectuate de {member.data.name.text}
                      </SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: member.data.investigations.html
                        }}
                      />
                    </Grid>
                  </>
                }

                {newsItems.length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <SectionTitle component="h2">
                        Articole scrise de {member.data.name.text}
                      </SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <NewsCompactCarousel newsItems={newsItems} />
                    </Grid>
                  </>
                }

                {videos.length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <SectionTitle component="h2">
                        Video
                      </SectionTitle>
                    </Grid>

                    <Grid item xs={12}>
                      <VideosCompactCarousel videos={videos} />
                    </Grid>
                  </>
                }

                {photos.length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <SectionTitle component="h2">
                        Galerie Foto {member.data.name.text}
                      </SectionTitle>
                    </Grid>

                    <Grid item xs={12}>
                      <PhotoGallery photos={photos} />
                    </Grid>
                  </>
                }

                {similarTeamMembers.length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <SectionTitle component="h2">
                        Aceeași specialitate
                      </SectionTitle>
                    </Grid>

                    <Grid item xs={12}>
                      <TeamMembersCompactCarousel teamMembers={similarTeamMembers} />
                    </Grid>
                  </>
                }

                {similarHelpers.length > 0 &&
                  <>
                    <Grid item xs={12}>
                      <SectionTitle component="h2">
                        MONZA ARES Helper
                      </SectionTitle>
                    </Grid>

                    <Grid item xs={12}>
                      <HelpersCompactCarousel helpers={similarHelpers} onSelect={onSelectHelper} />
                    </Grid>
                  </>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={`${clsx("contactHolder", classes.wideContact)}`}>
                <GeneralContact location={location} locale={locale} selectedTeamMemberUid={member.uid} />
              </div>
            </Grid>
          </Grid>
        </main>
      </Container>
      <ContactPopup
        locale={locale}
        location={location}
        open={contactModalOpen}
        handleClose={() => setContactModalOpen(false)}
        selectedHelperUid={selectedHelperUid}
      />
    </>
  )
}

export default TeamMemberPage

export const query = graphql`
  query PrismicTeamMemberBySlug($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    proceduresPage: prismicProcedures(lang: { eq: $locale }) {
      uid
    }

    newsPage: prismicNewsPage(lang: { eq: $locale }) {
      uid
    }

    teamPage: prismicTeam(lang: { eq: $locale }) {
      uid
    }

    member: prismicTeamMember(uid: { eq: $slug }, lang: { eq: $locale }) {
      id
      uid
      last_publication_date
      first_publication_date
      data {
        specialisations {
          specialisation: specialization {
            document {
              ... on PrismicSpecialisation {
                uid
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        entitling
        cv {
          html
          text
        }
        description {
          html
          text
        }
        investigations {
          html
          text
        }
        name {
          text
        }
        procedures {
          title {
            text
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          procedure {
            id
            document {
              ... on PrismicProcedure {
                id
                lang
                uid
                data {
                  image {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  categories {
                    category {
                      uid
                    }
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        videos {
          embed {
            id
            embed_url
            title
            thumbnail_url
          }
        }
        photo_gallery {
          photo {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
            url
          }
        }
        profile_image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }
    
    news: allPrismicNews(
      filter: {
        lang: { eq: $locale }
        data: { author: { uid: { eq: $slug } } }
      }
      limit: 10
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }            
            title {
              text
            }
          }
        }
      }
    }
  }
`

TeamMemberPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    proceduresPage: PropTypes.object.isRequired,
    newsPage: PropTypes.object.isRequired,
    teamPage: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
    news: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pageSlug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
