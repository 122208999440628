import React from "react"

import PropTypes from "prop-types"

import ImageGallery from "react-image-gallery";

const PhotoGallery = ({ photos }) => {
  const images = photos.map(photo => ({
    original: photo.url,
    thumbnail: photo.thumbnail,
  }))

  return <ImageGallery
    items={images}
    showPlayButton={true}
    autoPlay={true}
    slideInterval={5000}
  />
}

export default PhotoGallery

PhotoGallery.propTypes = {
  photos: PropTypes.array.isRequired,
}
