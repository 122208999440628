import React from "react"
import PropTypes from "prop-types"

import makeStyles from '@material-ui/core/styles/makeStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Card from "@material-ui/core/Card"
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

const ThumbCard = withStyles(() => ({
  root: {
    padding: 0
  },
}))(Card)

const useStyles = makeStyles(theme => ({
  media: {
    backgroundSize: "contain",
    height: 160,
    marginBottom: theme.spacing(1),
    backgroundPosition: "center",

    [theme.breakpoints.down("xs")]: {
      height: 80,
    },
  },

  name: {
    color: theme.palette.aresBlue.main,
    fontSize: 16,
    fontWeight: 700,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box'
  },
  specialisation: {
    color: theme.palette.aresBlue.main,
    fontSize: 14,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box'
  },
}))

const HelperCarouselThumbCard = ({ helper, onSelect }) => {
  const classes = useStyles()

  return (
    <ThumbCard elevation={0} square>
      <CardActionArea onClick={() => onSelect(helper.uid)}>
        <CardMedia>
          <GatsbyImage
            loading="eager"
            className={classes.media}
            image={helper.data.profile_image.gatsbyImageData}
            alt={helper.data.profile_image.alt ?? helper.data.name.text}
          />
        </CardMedia>

        <Typography component="div" className={classes.name}>
          {helper.data.name.text}
        </Typography>

        {helper.data.specialisations.length > 0 && helper.data.specialisations.map(({ specialisation }) =>
          <Typography className={classes.specialisation} key={specialisation.document.id}>
            {specialisation.document.data.title.text}
          </Typography>
        )}

      </CardActionArea>
    </ThumbCard>
  )
}

export default HelperCarouselThumbCard

HelperCarouselThumbCard.propTypes = {
  helper: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}
