import React from "react"
import PropTypes from "prop-types"


import makeStyles from '@material-ui/core/styles/makeStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Card from "@material-ui/core/Card"
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

const ThumbCard = withStyles(() => ({
  root: {
    padding: 0
  },
}))(Card)

const useStyles = makeStyles(theme => ({
  media: {
    backgroundSize: "contain",
    height: 160,
    marginBottom: theme.spacing(1),
    backgroundPosition: "center",

    [theme.breakpoints.down("xs")]: {
      height: 80,
    },
  },

  title: {
    fontSize: 16,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box'
  },
}))

const ProcedureThumbCard = ({ procedure }) => {
  const classes = useStyles()

  return (
    <ThumbCard elevation={0} square>
      <CardActionArea component={procedure.slug ? Link : 'div'} to={procedure.slug} disabled={!procedure.slug}>
        <CardMedia>
          <GatsbyImage
            loading="eager"
            className={classes.media}
            image={procedure.data.image.gatsbyImageData}
            alt={procedure.data.image.alt ?? procedure.data.title.text}
          />
        </CardMedia>

        <Typography component="div" variant="h5" className={classes.title}>
          {procedure.data.title.text}
        </Typography>

      </CardActionArea>
    </ThumbCard>
  )
}

export default ProcedureThumbCard


ProcedureThumbCard.propTypes = {
  procedure: PropTypes.object.isRequired,
}
