import React from "react"
import PropTypes from "prop-types"

import NewsItemThumbCard from "../cards/NewsItemThumbCard"
import BaseCarousel from "./BaseCarousel"

const NewsCompactCarousel = ({ newsItems }) => (
  <BaseCarousel
    elements={newsItems}
    slidesToShowXs={2}
    render={newsItem => <NewsItemThumbCard newsItem={newsItem} />}
  />
)

export default NewsCompactCarousel

NewsCompactCarousel.propTypes = {
  newsItems: PropTypes.array.isRequired,
}
