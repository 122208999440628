import React, { useState } from "react"
import PropTypes from "prop-types"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded"

const useStyles = makeStyles(theme => ({
  box: {
    position: "relative",
  },
  playIcon: {
    position: "absolute",
    fontSize: 86,
    color: "rgba(255, 255, 255, 0.3)",
    top: "calc(50% - 43px)",
    left: "calc(50% - 43px)",
  },
  media: {
    height: 175,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      height: 175,
    },
    [theme.breakpoints.down("xs")]: {
      height: 175,
    },
  },

  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
}))

const VideoThumbCard = ({ video }) => {
  const classes = useStyles()
  const [mediaLoaded, setMediaLoaded] = useState(false)

  const videoId = new URLSearchParams(video.embedUrl.replace(
    "https://www.youtube.com/watch?",
    ""
  )).get('v')

  return (
    <Card elevation={0} square className={classes.box}>
      <CardActionArea onClick={() => setMediaLoaded(true)}>
        <div style={{ position: "relative" }}>

          {!mediaLoaded && <>
            <span className={classes.playIcon}>
              <PlayCircleOutlineRoundedIcon fontSize="inherit" />
            </span><CardMedia
              className={classes.media}
              image={video.thumbnailUrl}
              title={video.title}
            />
          </>}

          {mediaLoaded && <CardMedia
            component="iframe"
            className={classes.media}
            src={`https://www.youtube-nocookie.com/embed/${videoId}?feature=oembed`}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />}

          <Typography component="div" variant="h5" className={classes.title}>
            {video.title}
          </Typography>
        </div>
      </CardActionArea>
    </Card>
  )
}

export default VideoThumbCard

VideoThumbCard.propTypes = {
  video: PropTypes.object.isRequired,
}
