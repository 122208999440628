import React from "react"
import PropTypes from "prop-types"

import makeStyles from '@material-ui/core/styles/makeStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Card from "@material-ui/core/Card"
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

const ThumbCard = withStyles(() => ({
  root: {
    padding: 0
  },
}))(Card)

const useStyles = makeStyles(theme => ({
  media: {
    backgroundSize: "contain",
    height: 160,
    marginBottom: theme.spacing(1),
    backgroundPosition: "center",

    [theme.breakpoints.down("xs")]: {
      height: 80,
    },
  },

  name: {
    color: theme.palette.aresBlue.main,
    fontSize: 16,
    fontWeight: 700,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box'
  },
  entitling: {
    color: theme.palette.aresBlue.main,
    fontSize: 14,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box'
  },
}))

const TeamMemberThumbCard = ({ teamMember }) => {
  const classes = useStyles()

  return (
    <ThumbCard elevation={0} square>
      <CardActionArea component={Link} to={teamMember.slug}>
        <CardMedia>
          <GatsbyImage
            loading="eager"
            className={classes.media}
            image={teamMember.data.profile_image.gatsbyImageData}
            alt={teamMember.data.profile_image.alt ?? ""}
          />
        </CardMedia>

        <Typography component="div" className={classes.name}>
          {teamMember.data.name.text}
        </Typography>
        <Typography component="div" className={classes.entitling}>
          {teamMember.data.entitling}
        </Typography>

      </CardActionArea>
    </ThumbCard>
  )
}

export default TeamMemberThumbCard

TeamMemberThumbCard.propTypes = {
  teamMember: PropTypes.object.isRequired,
}
